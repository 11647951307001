export default [
  // {
  //   title: 'Dashboard',
  //   route: 'dashboard',
  //   icon: 'HomeIcon',
  // },
  // {
  //   title: 'FAB',
  //   route: 'fab',
  //   icon: 'FileIcon',
  // },
  {
    title: 'FAB',
    icon: 'FileIcon',
    children: [
      {
        title: 'FAB',
        route: 'all-fab',
        icon: 'FileIcon'
      },
      {
        title: 'Sales Order',
        route: 'all-sales-order',
        icon: 'FileIcon'
      }
    ]
  },
  {
    title: 'Customer',
    route: 'customer',
    icon: 'FileIcon',
  },
  {
    title: 'Master',
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'Location',
        route: 'all-location',
        icon: 'FileIcon',
      },
      {
        title: 'Terms and Condition',
        route: 'tnc',
        icon: 'FileIcon',
      },
      {
        title: 'PDP',
        route: 'pdp',
        icon: 'FileIcon',
      },
      {
        title: 'Price Catalog',
        route: 'price-catalog',
        icon: 'FileIcon',
      },
      {
        title: 'Contrac',
        route: 'contrac',
        icon: 'FileIcon',
      },
      {
        title: 'Subscription Template',
        route: 'subscription-template',
        icon: 'FileIcon',
      },
      {
        title: 'Sales Order',
        icon: 'LayersIcon',
        children: [
          {
            title: 'Status',
            route: 'sales-order-status',
            icon: 'FileIcon',
          }
        ]
      },
      {
        title: 'Customer',
        icon: 'LayersIcon',
        children: [
          {
            title: 'Address Type',
            route: 'customer-address-type',
            icon: 'FileIcon',
          },
          {
            title: 'Area',
            route: 'customer-area',
            icon: 'FileIcon',
          },
          {
            title: 'Attachment Type',
            route: 'customer-attachment-type',
            icon: 'FileIcon',
          },
          {
            title: 'Group',
            icon: 'FileIcon',
            children: [
              {
                title: 'Group Area',
                route: 'customer-group-area',
                icon: 'FileIcon',
              },
              {
                title: 'Group Type',
                route: 'customer-group-type',
                icon: 'FileIcon'
              }
            ]
          },
          {
            title: 'Status',
            route: 'customer-status',
            icon: 'FileIcon',
          },
          {
            title: 'Title',
            route: 'customer-title',
            icon: 'FileIcon',
          },
          {
            title: 'Type',
            route: 'customer-type',
            icon: 'FileIcon',
          },
          // {
          //   title: 'Customer Sub Area',
          //   route: 'customer-sub-area',
          //   icon: 'FileIcon',
          // }
        ]
      },
      // {
      //   title: 'Location',
      //   icon: 'LayersIcon',
      //   children: [
      //     {
      //       title: 'Location',
      //       route: 'location',
      //       icon: 'FileIcon',
      //     },
      //     {
      //       title: 'Location area',
      //       route: 'location-area',
      //       icon: 'FileIcon',
      //     },
      //     {
      //       title: 'Location Address',
      //       route: 'location-address',
      //       icon: 'FileIcon',
      //     },
      //   ]
      // },
      {
        title: 'Product',
        icon: 'LayersIcon',
        children: [
          {
            title: 'Product',
            route: 'product',
            icon: 'FileIcon',
          },
          {
            title: 'Product Category',
            route: 'product-category',
            icon: 'FileIcon',
          },
          {
            title: 'Product Type',
            route: 'product-type',
            icon: 'FileIcon',
          },
          {
            title: 'Tax',
            route: 'tax',
            icon: 'FileIcon',
          },
        ]
      },
      {
        title: 'Promotion',
        icon: 'LayersIcon',
        children: [
          {
            title: 'Promotion',
            route: 'promotion',
            icon: 'FileIcon',
          },
          {
            title: 'Promotion type',
            route: 'promotion-type',
            icon: 'FileIcon',
          },
          {
            title: 'Promotion status',
            route: 'promotion-status',
            icon: 'FileIcon',
          },
        ]
      },
    ]
  }, 
  {
    title: 'Charge Log',
    route: 'charge-log',
    icon: 'FileIcon',
  },
  // {
  //   header: 'Tools',
  //   icon: 'DatabaseIcon',
  //   children: [
  //     {
  //       title: 'customer activation',
  //       route: 'customer-activation',
  //       icon: 'FileIcon',
  //     },
  //   ]
  // }
]
