<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      <span class="mr-25">V1.0.0</span>
      COPYRIGHT ©
      <b-link
        class="ml-25"
        href="https://netciti.co.id"
        target="_blank"
      >PT. Netciti Persada</b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved.</span>
    </span>

    <span class="float-md-right d-none d-md-block">&nbsp;</span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
